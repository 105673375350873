body {
  font-family: "Montserrat", sans-serif;
}

.bg {
  background: linear-gradient(
      271deg,
      #ebfff5 0.44%,
      rgba(235, 255, 245, 0) 50.31%
    ),
    linear-gradient(90deg, #ebfff5 2.15%, #fff 53.12%);
}
.business {
  background: linear-gradient(
      273deg,
      rgba(235, 255, 245, 0.5) 7.83%,
      rgba(235, 255, 245, 0) 49.85%
    ),
    linear-gradient(
      121deg,
      rgba(235, 255, 245, 0.5) 14.46%,
      rgba(240, 250, 246, 0.26) 33.72%,
      rgba(245, 245, 247, 0) 53.91%
    );
}

.background {
  background: linear-gradient(
      273deg,
      rgba(235, 255, 245, 0.5) 7.83%,
      rgba(235, 255, 245, 0) 49.85%
    ),
    linear-gradient(
      121deg,
      rgba(235, 255, 245, 0.5) 14.46%,
      rgba(240, 250, 246, 0.26) 33.72%,
      rgba(245, 245, 247, 0) 53.91%
    );
}

.container {
  height: 40rem;
  direction: rtl;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #017a59 #eeeeee; /* For Firefox */
}

.container::-webkit-scrollbar {
  width: 0.3vw;
}

.container::-webkit-scrollbar-thumb {
  background-color: #017a59;
  border-radius: 80px;
}

.container::-webkit-scrollbar-track {
  background-color: #eeeeee;
}
/* .container::-webkit-scrollbar-button {
  width: 50px;
  height: 120px;
} */

.text {
  direction: ltr;
}

/* @keyframes moveLogos {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10%);
  }

  60% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

.logos {
  animation: moveLogos 4s infinite linear;
} */

@keyframes moveLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
/* @keyframes moveLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.animate {
  width: 300%;
  top: 50%;
  transform: translate(0, -50%);
  white-space: nowrap;
  overflow: hidden;
  animation: moveLeft 6s infinite linear;
  /* display: inline-flex; */
}

.active-link {
  color: #017a59;
  border-bottom: 3px solid #017a59;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: pointer;
  padding-bottom: 8px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02rem;
}

.bgs {
  background: linear-gradient(
      273deg,
      rgba(235, 255, 245, 0.5) 7.83%,
      rgba(235, 255, 245, 0) 49.85%
    ),
    linear-gradient(
      121deg,
      rgba(235, 255, 245, 0.5) 14.46%,
      rgba(240, 250, 246, 0.26) 33.72%,
      rgba(245, 245, 247, 0) 53.91%
    );
}
.bg-text:hover {
  cursor: pointer;
  border-radius: 0.375rem;
  background: linear-gradient(
      0deg,
      rgba(235, 255, 245, 0.2) 0%,
      rgba(235, 255, 245, 0.2) 100%
    ),
    linear-gradient(97deg, #ebfff5 0%, #fff 33.33%);
}

.contain:hover {
  cursor: pointer;
  background-color: #017a59;
}

.text1 {
  color: #017a59;
  opacity: 0.4;
}
.text2 {
  color: #1d1d1f;
}

.contain:hover .text1 {
  color: white !important;
  opacity: 0.4;
}
.contain:hover .text2 {
  color: white !important;
}

.active-section {
  color: #017a59;
}

.line-container {
  position: relative;
  width: 0.225rem;
  height: 45.725rem;
  background-color: #e8e9eb;
  margin-top: 4.38rem;
}

.line-container2 {
  position: relative;
  width: 0.225rem;
  height: 22.9rem;
  background-color: #e8e9eb;
  margin-top: 4.38rem;
}
.line2 {
  border-left: 4px solid #017a59;
  transition: border-left-color 0.3s ease-in-out;
  border-radius: 10px;
  height: 5rem;
  position: absolute;
}

.line {
  border-left: 4px solid #017a59;
  transition: border-left-color 0.3s ease-in-out;
  border-radius: 10px;
  height: 10.85rem;
  position: absolute;
}

.shade {
  box-shadow: 0px 0px 20px 0px rgba(29, 29, 31, 0.1);
}

.shades {
  filter: drop-shadow(0px 0px 25px rgba(1, 122, 89, 0.16));
}
.shadows {
  box-shadow: 0px 10px 23px 0px rgba(1, 122, 89, 0.27);
}
.shadows2 {
  box-shadow: 0px 4px 16px 0px rgba(1, 122, 89, 0.1);
}
.sha {
  box-shadow: 0px 0px 16px 1px rgba(1, 122, 89, 0.1);
}
.car:hover {
  background: rgba(235, 255, 245, 0.4);
}
.line3 {
  border-left: 4px solid #017a59;
  transition: border-left-color 0.3s ease-in-out;
  border-radius: 10px;
  height: 9.8375rem;
  position: absolute;
}
.line-container3 {
  position: relative;
  width: 0.225rem;
  height: 79.075rem;
  background-color: #e8e9eb;
  margin-top: 4.38rem;
}
.line-container4 {
  position: relative;
  width: 0.225rem;
  height: 45.3rem;
  background-color: #e8e9eb;
  margin-top: 4.38rem;
}
.line4 {
  border-left: 4px solid #017a59;
  transition: border-left-color 0.3s ease-in-out;
  border-radius: 10px;
  height: 5rem;
  position: absolute;
}
.show {
  /* display: block; */
  animation: slideIn 1s forwards;
}

.hide {
  animation: slideOut 1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
